




































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineELN2v2Argumentation1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        argumentation1Answer: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: "Fundamental Skills Guiding Question: What is your dilution plan for creating standard dye solutions between the given concentration ranges? How are these standard solutions used to create a Beer's Law Plot?",
          inputName: 'argumentation1Answer',
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
